<template>
  <v-container fill-height>
    <QrcodeStream :camera="cameraStatus" @decode="onDecode" @init="onInit" class="d-flex">
      <v-row style="height: 100%" no-gutters class="flex-column white--text">
        <v-col class="pa-4 text-center flex-grow-0 header text-uppercase">
          <h2>{{ headerText }}</h2>
        </v-col>
        <v-spacer class="flex-grow-1 flex-shrink-0" />
        <v-col v-if="footerText" class="pa-4 text-center flex-grow-0 footer">
          <h2>{{ footerText }}</h2>
        </v-col>
      </v-row>
      <div v-if="isPaused" class="pause-overlay flex-column d-flex justify-center align-center">
        <h3 class="white--text mb-1">Fotocamera in pausa</h3>
        <v-btn color="primary" @click="reactivateCamera">RIATTIVA</v-btn>
      </div>
    </QrcodeStream>
  </v-container>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { CREATE_MEAL } from "../graphql/mutations";
import Vue from "vue";
const PUT_SIGN = require("../graphql/mutations/PutSign.gql");

export default {
  components: { QrcodeStream },
  data() {
    return {
      error: "",
      parsing: false,
      verifing: false,
      currentSignType: "",
      isPaused: false,
      timeout: null,
    };
  },
  computed: {
    headerText() {
      if (this.parsing) return "Invio richiesta...";
      if (this.verifing) return "In attesa del secondo QR";
      return "Scannerizza QR";
    },
    footerText() {
      return this.currentSignType && `Rilevato QR ` + this.$tc(`models.${this.currentSignType}`, 1);
    },
    cameraStatus() {
      return this.isPaused ? "off" : "auto";
    },
  },
  mounted() {
    this.resetTimeout();
  },
  methods: {
    reactivateCamera() {
      this.isPaused = false;
      this.resetCameraState();
      this.resetTimeout();
    },
    resetCameraState() {
      this.parsing = false;
      this.verifing = false;
      this.currentSignType = "";
    },
    resetTimeout() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => (this.isPaused = true), 20000);
    },
    async onDecode(result) {
      if (this.parsing) return;
      this.parsing = true;
      try {
        let { sign_type, ...input } = JSON.parse(result);
        if (!sign_type) {
          this.resetCameraState();
          return;
        }
        this.resetTimeout();
        this.currentSignType = sign_type;
        switch (sign_type) {
          case "meal":
          case "event":
            if (this.verifing && !input.registration_code) {
              this.resetCameraState();
              return;
            }
            try {
              const { data } = await this.$apollo.mutate({
                mutation: sign_type === "meal" ? CREATE_MEAL : PUT_SIGN,
                variables: { input },
              });
              if (data === null) {
                this.resetCameraState();
                return;
              }
              const verified = this.checkIfVerified(data, sign_type);
              if (verified) {
                this.resetCameraState();
                this.showAlert("Firma avvenuta con successo");
              } else {
                this.verifing = true;
                this.parsing = false;
              }
            } catch (error) {
              this.handleMutationError(error);
            }
            break;
          default:
            this.resetCameraState();
            break;
        }
      } catch {
        this.resetCameraState();
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        this.handleInitError(error);
      }
    },
    checkIfVerified(data, sign_type) {
      switch (sign_type) {
        case "meal":
          return data.createMeal.verified;
        case "event":
          return data.putSign.pivot.verified;
      }
    },
    handleMutationError(error) {
      this.resetCameraState();
      if (error.message.includes("unauthorized"))
        this.showAlert("You are not authorized to perform this action.");
    },
    handleInitError(error) {
      if (error.name === "NotAllowedError") {
        this.error = "ERROR: you need to grant camera access permission";
      } else if (error.name === "NotFoundError") {
        this.error = "ERROR: no camera on this device";
      } else if (error.name === "NotSupportedError") {
        this.error = "ERROR: secure context required (HTTPS, localhost)";
      } else if (error.name === "NotReadableError") {
        this.error = "ERROR: is the camera already in use?";
      } else if (error.name === "OverconstrainedError") {
        this.error = "ERROR: installed cameras are not suitable";
      } else if (error.name === "StreamApiNotSupportedError") {
        this.error = "ERROR: Stream API is not supported in this browser";
      }
      console.log(error);
    },
    showAlert(message) {
      this.isPaused = true;
      Vue.nextTick(() => alert(message));
    },
  },
};
</script>

<style scoped>
.header,
.footer {
  background-color: rgba(0, 0, 0, 0.7);
}
.pause-overlay {
  top: -100%;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.9);
}
</style>
